import React from "react";
import "./tAndC.scss";

const TAndC = () => {
    return <>
        <div className="t-c-container">
            <h1 className="tc-heading-text">Terms of Services</h1>
            <p className="welcome-text">Welcome to <span style={{ fontWeight: 700 }}>Enuncia.ai</span>, the leading app for speech impediment detection and treatment. Before you embark on your journey to improved speech and communication, it's important to understand our <span style={{ fontWeight: 700 }}>Terms and Conditions</span>. By using our app, you agree to comply with the following terms:</p>

            <div className="tc-list">
                <p className="tc-list-text">1.&nbsp;
                    <span style={{ fontWeight: 700 }}>App Usage:</span>&nbsp;Enuncia.ai is intended for individuals seeking assistance with speech impediments and speech therapists. You must use the app in a manner consistent with its purpose and in compliance with all applicable laws and regulations.
                </p>
                <p className="tc-list-text">2.&nbsp;
                    <span style={{ fontWeight: 700 }}>Personalized Treatment:</span>&nbsp;Enuncia.ai provides personalized treatment plans designed to address your specific speech challenges. You are responsible for following the recommended exercises and activities as advised by the app or a qualified speech therapist.
                </p>
                <p className="tc-list-text">3.&nbsp;
                    <span style={{ fontWeight: 700 }}>Privacy & Data:</span>&nbsp;We take your privacy seriously. By using our app, you agree to our Privacy Policy, which outlines how we collect, store, and use your personal information. We are committed to protecting your data.
                </p>
                <p className="tc-list-text">4.&nbsp;
                    <span style={{ fontWeight: 700 }}>Intellectual Property:</span>&nbsp;All content, including text, images, and software, is the property of Enuncia.ai and is protected by copyright and intellectual property laws. You may not reproduce, modify, or distribute our content without permission.
                </p>
                <p className="tc-list-text">5.&nbsp;
                    <span style={{ fontWeight: 700 }}>Liability:</span>&nbsp;Enuncia.ai is a tool for speech improvement, but we cannot guarantee specific results. We are not liable for any direct or indirect consequences of app usage.
                </p>
                <p className="tc-list-text">5.&nbsp;
                    <span style={{ fontWeight: 700 }}>Termination:</span>&nbsp;We reserve the right to terminate or suspend your access to the app if you violate these terms.
                </p>
            </div>
            <p className="welcome-text">Please read these <span style={{ fontWeight: 700 }}>Terms and Conditions</span> carefully. Your use of Enuncia.ai implies your acceptance of these terms. If you have any questions or concerns, please contact us. We are excited to have you on board and look forward to helping you achieve improved speech and communication through our app.</p>
        </div>
    </>
}

export default TAndC;